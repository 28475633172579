import * as React from 'react';
import { view } from 'react-easy-state';

export default view(() => {
  return (
    <section id='organizations' className='section has-text-dark'>
        <div className='container has-text-left pb-5'>
          <h1 className='subtitle'>We combine over 20 years of experience with product design, UX design, product management, research and education. Below is a selection of the companies and organisations we worked for.</h1>
        </div>
        <div className=''>
          <div className='container has-text-centered'>
            <div className='columns is-centered is-mobile is-multiline'>
              <div className='column py-4 is-one-third'>
                <h3 className=''>ASML</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>Eindhoven University of Technology</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>Atos Spain</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>The Robert Gordon University</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>Universiteit Hasselt</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>Fundación Virtualware Labs</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>Peerby</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>Intrasoft</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>Norwich University of Arts</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>DesignLab.gr</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>Telcel</h3>
              </div>
              <div className='column py-4 is-one-third'>
                <h3 className=''>Claro Americas</h3>
              </div>
            </div>
          </div>
        </div>
    </section>
  )
});
