import * as React from 'react';
import { view } from 'react-easy-state';
import { useState } from 'react';
import { motion } from 'framer-motion';
import classNames from 'classnames';
import { useBoolean } from 'utils/Hook';

import * as css from './Hero.scss';

import homeBackground from 'media/kunal-shinde--f0YLss50Bs-unsplash-1.jpg';

export default view(() => {

  const [page, setPage] = useState(0);
  

  // Add staggering effect to the children of the container
  const containerVariants = {
    before: {},
    after: { transition: { staggerChildren: 0.06 } },
  };

  // Variants for animating the sentence
  const easeVariants = {
    before: {
      opacity: 0,
    },
    after: {
      opacity: 1,
      transition: {
        ease: [0.17, 0.67, 0.83, 0.67],
      },
    }
  };


  return (
    <section id='/home/start' className='hero is-fullheight-with-navbar is-bold has-text-grey is-dark pb-6'>
      <img alt='Photo by Kunal Shinde @editholic7 on Unsplash' className={classNames(css.heroBackground, css.isTransparent, css.hasBackground, 'is-mobile')} src={homeBackground} />
      <motion.div className='hero-body'
        variants={containerVariants}
        initial={'before'}
        animate={'after'}
      >
        <div className='container'>
        <div className=''>
          <motion.div className='has-text-left'
            variants={easeVariants}
          >
          
            <h1 className='super title has-text-light'>
              We help you <br /><span className='is-orange-underlined'>design</span> your <br />next product
            </h1>
            <h2 className='super subtitle has-text-light pt-6 pb-5'>
              Do you need to materialise <br />your sustainability ambitions?
            </h2>
            <a className='button is-size-5-desktop is-orange mr-5'
              onClick={
                (event) => {
                  event.preventDefault();
                  document.getElementById('/home/about').scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}>
              <span className=''>About us</span>
              <span className='icon'>
                <i className='fas fa-chevron-down'></i>
              </span>
            </a>
            <br className='is-hidden-tablet' />
            <br className='is-hidden-tablet' />
            <a className='button is-size-5-desktop is-orange'
              onClick={
                (event) => {
                  event.preventDefault();
                  document.getElementById('/home/services').scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}>
              <span className=''>How we can help</span>
              <span className='icon'>
                <i className='fas fa-chevron-down'></i>
              </span>
            </a>
          </motion.div>
        </div>
        </div>
      </motion.div>
      <div className='hero-footer has-text-right'>Photo by <a href="https://unsplash.com/@editholic7?">Kunal Shinde</a> on <a href="https://unsplash.com/">Unsplash</a></div>
    </section>
  )
});

