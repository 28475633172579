import * as React from 'react';
import { view } from 'react-easy-state';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Hero from './Hero';
import About from './About';
import Services from './Services';
import Team from './Team';
import Organizations from './Organizations';


export default view((props) => {
  const { pathname } = useLocation();
  
  const routeChild = '/home/' + props.route;
  

  useEffect(() => {
    if (pathname=='/' || pathname=='/home' ) {
      window.scrollTo(0, 0);
    } else if (document.getElementById(pathname)){
      document.getElementById(pathname).scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }, [pathname]);

  if (routeChild && document.getElementById(routeChild)){
    document.getElementById(routeChild).scrollIntoView({ behavior: 'smooth', block: 'start' })
  }


  return (
    <React.Fragment>
      <Hero />
      <About />
      <Services />
      <Team />
    </React.Fragment>
  )
});

