import * as React from 'react';
import { view } from 'react-easy-state';
import JesusPic from 'media/Jesus_profile_pic_web.png';
import MendelPic from 'media/Mendel_profile_pic_web.png';

export default view(() => {
  return (
    <section id='/home/team' className='section has-background-white'>
        <div className='pb-3 mb-3'>
          <div className='container has-text-left'>    
            <h1 className='title is-size-2 has-text-dark'>Team</h1>
          </div>
        </div>
      <div className='container has-text-left has-text-dark'>
          <div className='columns is-vcentered is-centered is-desktop is-multiline'>
          <div className='column is-half pb-4 has-text-centered'>
                <figure className='image'>
                    <img className='profile-pic is-rounded' style='max-width: 40%;' src={MendelPic} />
                </figure>
              <h1 className='subtitle mt-3 has-text-weight-semibold	'>
                Mendel Broekhuijsen &nbsp;<a href='https://www.linkedin.com/in/mendelbroekhuijsen' target='_blank'><span className='icon has-text-green'><i class="fab fa-linkedin"></i></span></a>
              </h1>
              <div className='content'>
              <p style={{ lineHeight: '1.5' }}>I am a passionate product and experience designer with the ambition to make global impact with my work and contribute to a better future.</p>
              </div>
            </div>
            <div className='column is-half pb-4 has-text-centered'>
                <figure className='image'>
                    <img className='profile-pic is-rounded' style='max-width: 40%;' src={JesusPic} />
                </figure>
              <h1 className='subtitle mt-3 has-text-weight-semibold'>
                Jesús Muñoz-Alcántara &nbsp;<a href='https://www.linkedin.com/in/jmunoza/' target='_blank'><span className='icon has-text-green'><i class="fab fa-linkedin"></i></span></a>
              </h1>
              <div className='content'>
              <p style={{ lineHeight: '1.5' }}>I drive pluriversality in the design process and inspire creativity through freedom, partnership and participation.</p>
              </div>
            </div>
          </div>
      </div>
    </section>
  )
});
