import * as React from 'react';
import { view } from 'react-easy-state';
import classNames from 'classnames';
import Pic1 from 'media/public_green.svg';
import Pic2 from 'media/handyman_green.svg';

export default view(() => {
  return (
    <React.Fragment>
      <section id='/home/about' className='section has-background-white'>
        <div>
          <div className='container has-text-left'>
          <h1 className='title'>
              About Ecoforma
            </h1>
            <h2 className='subtitle mt-3 mb-6'>We are a Dutch design consultancy with global ambitions and a 100-year vision. We help companies and organisations with the design, redesign or development of electronic products. Depending on the project, we can support the design process with workshops, co-creation sessions, design research, structured design sprints or by temporarily joining the team.</h2>
            <div className='columns is-vcentered is-desktop is-multiline'>
            <div className='column is-half-desktop mb-5 pb-5 is-hidden-desktop'>
                <figure className='image'>
                  <img className='is-rounded' style='max-width: 48%' src={Pic1} />
                </figure>
              </div>
              <div id='/home/vision' className={classNames('column is-half-desktop py-4 mt-4')}>
                <h1 className='title'>
                    Our vision for the future
                </h1>
                <h2 className='subtitle mt-2'>
                    <p>Designers are co-responsible for current global consumerism and the related electronic waste. We believe in a future where products are designed in such a way that they help to restore harmony between people and planet. That future only happens if we design it today.</p>                
                </h2>
              <a className='button is-orange is-size-5-desktop mr-5'
                  onClick={
                    (event) => {
                      event.preventDefault();
                      document.getElementById('/home/mission').scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }}>
                    <span>Our mission</span>
                    <span className='icon'>
                      <i className='fas fa-chevron-down'></i>
                    </span>
              </a>
              <a className='button is-orange is-size-5-desktop'
                  onClick={
                    (event) => {
                      event.preventDefault();
                      document.getElementById('/home/contact').scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }}>
                    <span>Get in touch</span>
                    <span className='icon'>
                      <i className='fas fa-chevron-right'></i>
                    </span>
              </a>
              </div>
              <div className='column is-half-desktop mb-6 pb-6 is-hidden-touch'>
                <figure className='image'>
                  <img className='is-rounded' style='max-width: 48%' src={Pic1} />
                </figure>
              </div>
              <div className='column is-half-desktop mt-3 pt-3'>
                <figure className='image'>
                  <img className='is-rounded' style='max-width: 48%' src={Pic2} />
                </figure>
              </div>
              <div id='/home/mission' className={classNames('column is-half-desktop py-4 mt-4')}>
                <h1 className='title'>
                    Ecoforma mission
                </h1>
                <h2 className=' subtitle mt-2'>
                    <p>Our mission is to lead and educate companies on designing electronic products and underlying systems that are durable, repairable and can be repurposed to extend their lifecycle.</p>                
                </h2>
              <a className='button is-orange is-size-5-desktop mr-5'
                  onClick={
                    (event) => {
                      event.preventDefault();
                      document.getElementById('/home/services').scrollIntoView({ behavior: 'smooth', block: 'start' })
                    }}>
                    <span>Our design services</span>
                    <span className='icon'>
                      <i className='fas fa-chevron-down'></i>
                    </span>
              </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
});
