import * as React from 'react';
import classNames from 'classnames';
import { store, view } from 'react-easy-state';
import { useBoolean } from 'utils/Hook';

import ecoformaLogo from 'media/ecoforma-logo-website.svg';

export default view(() => {
    const menuState = useBoolean(false); 
    let isActive = false
    return (
      <div className='has-background-white'>
      <nav className='navbar'>
        <div className='container '>
          <div className='navbar-brand'>
            <a className='navbar-item' href='/'>
              <img src={ecoformaLogo} alt='Ecoforma: Design consultancy'width='250'/>
            </a>
            <span className={classNames('navbar-burger', menuState.value ? 'is-active': '')} data-target='navbarMenuHeroA' 
            style='clip-path: circle(20px at 50% 50%); background-color: white;'
            onClick={
              (event) => {
                event.preventDefault();
                menuState.inverse();
              }} >
              <span></span>
              <span></span>
              <span></span>
            </span>
          </div>
          <div id='navbarMenuHeroA' className={classNames('navbar-menu', menuState.value ? 'is-active': '')}>
            <div className='navbar-end'>
              <a className='navbar-item is-size-5-desktop is-active'
              onClick={
                (event) => {
                  //event.preventDefault();
                  if (menuState.value) menuState.inverse();
                }}>
                Home
              </a>
              <a href='/home/about' className='navbar-item is-size-5-desktop'
              onClick={
                (event) => {
                  event.preventDefault();
                  if (menuState.value) menuState.inverse();
                  document.getElementById('/home/about').scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}>
                  <span>About</span>
              </a>
              <a href='/home/services' className='navbar-item is-size-5-desktop'
              onClick={
                (event) => {
                  event.preventDefault();
                  if (menuState.value) menuState.inverse();
                  document.getElementById('/home/services').scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}>
                  <span>Services</span>
              </a>
              <a href='/home/team' className='navbar-item is-size-5-desktop'
              onClick={
                (event) => {
                  event.preventDefault();
                  if (menuState.value) menuState.inverse();
                  document.getElementById('/home/team').scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}>
                  <span>Team</span>
              </a>
              <a href='/home/contact' className='navbar-item is-size-5-desktop'
              onClick={
                (event) => {
                  event.preventDefault();
                  if (menuState.value) menuState.inverse();
                  document.getElementById('/home/contact').scrollIntoView({ behavior: 'smooth', block: 'start' })
                }}>
                  <span>Contact</span>
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
    )
  })