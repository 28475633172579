import * as React from 'react';
import { view } from 'react-easy-state';
import Pic1 from 'media/interests_green.svg';
import Pic2 from 'media/groups_green.svg';
import Pic3 from 'media/hardware_green.svg';

import { analytics } from "utils/FirebaseAnalytics";

const gtag_events = [
    {
        item_id: "ECO_SERVICES_0",
        item_name: "Repurpose workshop",
        index: 0,
        item_category: "Services",
    },
    {
        item_id: "ECO_SERVICES_1",
        item_name: "Repurpose design sprint",
        index: 1,
        item_category: "Services",
    },
    {
        item_id: "ECO_SERVICES_2",
        item_name: "Repurpose training",
        index: 2,
        item_category: "Services",
    },
]

export default view(() => {
  return (
    <React.Fragment>
      <section className='section has-background-white'>
      <div className=''>
          <div id='/home/services' className='container has-text-left'>    
            <h1 className='title is-size-2 has-text-dark'>Our design services</h1>
          </div>
        </div>
        <div className='pt-3 mt-3'>
            <div className='container has-text-left'>
            <div className='columns is-vcentered is-desktop is-multiline'>
              <div id='/home/workshop' className='column is-one-third py-4 mt-4'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-4by3'>
                            <img src={Pic1} alt='' style='max-width: 35%'/>
                            </figure>
                        </div>
                        <div className='card-content'>
                            <div className='media'>
                            <div className='media-content'>
                                <p className='subtitle'>Get fresh ideas with our <span className='is-orange-underlined'>repurpose workshop</span></p>
                            </div>
                            </div>
                            <div className='content'>
                            </div>
                        </div>
                        <footer class='card-footer'>
                        <a class='card-footer-item'
                            onClick={
                                    (event) => {
                                      event.preventDefault();

                                      async () =>
                                        await analytics().logEvent('select_item', {
                                            item_list_id: 'ecoforma_services',
                                            item_list_name: 'Ecoforma services',
                                            items: [
                                              gtag_events[0]
                                            ]
                                          });

                                      document.getElementById('/home/team').scrollIntoView({ behavior: 'smooth', block: 'start' })
                            }}>
                            <span className='icon has-text-grey'>
                                <i className='fas fa-chevron-down'></i>
                            </span>
                        </a>
                        </footer>
                    </div>
                </div>
                <div id='/home/sprint' className='column is-one-third py-4 mt-4'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-4by3'>
                            <img src={Pic2} alt='' style='max-width: 35%'/>
                            </figure>
                        </div>
                        <div className='card-content'>
                            <div className='media'>
                            <div className='media-content'>
                                <p className='subtitle'>Kickstart your project with our <span className='is-orange-underlined'>repurpose design sprint</span></p>
                            </div>
                            </div>
                            <div className='content'>
                            </div>
                        </div>
                        <footer class='card-footer'>
                        <a class='card-footer-item'
                            onClick={
                                    (event) => {
                                      event.preventDefault();                                                             

                                      async () =>
                                        await analytics().logEvent('select_item', {
                                            item_list_id: 'ecoforma_services',
                                            item_list_name: 'Ecoforma services',
                                            items: [
                                              gtag_events[0]
                                            ]
                                          });
  
                                      document.getElementById('/home/team').scrollIntoView({ behavior: 'smooth', block: 'start' })
                            }}>
                            <span className='icon has-text-grey'>
                                <i className='fas fa-chevron-down'></i>
                            </span>
                        </a>
                        </footer>
                    </div>
                </div>
                <div id='/home/training' className='column is-one-third py-4 mt-4'>
                    <div className='card'>
                        <div className='card-image'>
                            <figure className='image is-4by3'>
                            <img src={Pic3} alt='' style='max-width: 35%'/>
                            </figure>
                        </div>
                        <div className='card-content'>
                            <div className='media'>
                            <div className='media-content'>
                                <p className='subtitle'><span className='is-orange-underlined'>Tailored design support</span> for your sustainable innovation needs</p>
                            </div>
                            </div>
                            <div className='content'>
                            </div>
                        </div>
                        <footer class='card-footer'>
                        <a class='card-footer-item'
                            onClick={
                                    (event) => {
                                      event.preventDefault();

                                      async () =>
                                        await analytics().logEvent('select_item', {
                                            item_list_id: 'ecoforma_services',
                                            item_list_name: 'Ecoforma services',
                                            items: [
                                              gtag_events[0]
                                            ]
                                          });
                                      
                                      document.getElementById('/home/team').scrollIntoView({ behavior: 'smooth', block: 'start' })
                            }}>
                            <span className='icon has-text-grey'>
                                <i className='fas fa-chevron-down'></i>
                            </span>
                        </a>
                        </footer>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        </section>
    </React.Fragment>
  )
});