import * as React from 'react';
import { view } from 'react-easy-state';

export default view(() => {
  return (
    <React.Fragment>
      <section className='section has-background-white'>
      <div className=''>
          <div className='container has-text-left'>    
            <h1 className='title is-size-2 has-text-dark'>Contact</h1>
          </div>
        </div>
        <div className='pt-4 mt-4'>
        <div className='container'>
          <div>
            <div className='columns is-vcentered is-mobile is-multiline'>
              <div className='column pb-4'>
                <a href='tel:+31618749143' className='button is-white is-size-5-desktop'>
                  <span className='icon'>
                    <i className='fas fa-phone-alt'></i>
                  </span>
                  <span>+31 (0) 618749143</span>
                </a><br />
                <a href='mailto:info@ecoforma.design' className='button is-white is-size-5-desktop'>
                  <span className='icon'>
                    <i className='far fa-envelope'></i>
                  </span>
                  <span> info@ecoforma.design</span>
                </a>
              </div>
              <div className='column pb-4'>
                
              </div>
            </div>
          </div>
        </div>
        </div>
      </section>
      <footer id='/home/contact' class='footer'>
        <div class='content has-text-centered'>
          <div className=''>
            <p className='small-text'>
            <span className='icon'><i class="fas fa-copyright"></i></span> {new Date().getFullYear()} Ecoforma | Privacy &amp; disclaimer | Terms &amp; conditions
            </p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  )
});
